<template>
  <div>
     <!--- 加入会议按钮 --->
     <van-button type="default" :loading="loading" :disabled="disabled"
          :loading-text="loadingText" class="button" @click="handleClick">
       <slot></slot>
     </van-button>

     <!-- 入会密码弹窗 -->
    <join-password :showPwdWin="showPwdWin" @close="closeWin(0)" @join-handle="handleJoin"></join-password>

    <!-- 等待主持人开始当前会议 弹窗---->
    <wait-host :showHostWin="showHostWin" :params="waitParams" :title="hostWinTitle"  @close="closeWin(1)" :detail="detail"></wait-host>

    <!-- 主持人正在开另外一场会议 弹窗---->
    <other-host :showOtherWin="showOtherWin" :params="otherParams" :title="otherWinTitle"  @close="closeWin(2)"></other-host>

    <!-- 是否结束当前正在进行中的会议 弹窗---->
    <end-win :showEndWin="showEndWin" :params="endParams" :title="endWinTitle"  @close="closeWin(3)" :detail="endDetail"></end-win>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import { THROTLE_TIME } from "@/constant/index";
import { setMeetInfo ,getMeetToken,getUserName,getUserId,setUserId,setUserName,getAppid,setAppid} from '@/utils/auth'
import { loganLog } from "@/utils/log"
import {landingConfirm} from '@/api/meet'

import { checkDevicePermission } from "@/utils/device";

import JoinPassword from '@/components/dialog/JoinPassword'
import WaitHost from '@/components/dialog/WaitHost'
import OtherHost from '@/components/dialog/OtherHost'
import EndWin from '@/components/dialog/EndWin'

export default {
  data() {
    return {
      showPwdWin: false,
      showHostWin: false,
      showOtherWin: false,
      showEndWin: false,
      conferenceNumber: '',
      detail: {},
      endDetail: {},
      endParams: {},
      waitParams: {},
      otherParams: {},
      hostWinTitle: '',
      otherWinTitle: '',
      endWinTitle: '',
      loading: false,
      conferenceNo: '',
      userName: '',
      userId:'',
      appId:[],
    }
  },

  props: {
    isNew: { default : false },
    loadingText: { default : '请求中...' },
    disabled:{default : false}
  },

  components: {
    JoinPassword,
    WaitHost,
    OtherHost,
    EndWin
  },
  mounted() {

    //加入密码会议的事件监听

    this.$eventBus.$on('join-password-handle', data => {
      this.showPwdWin = true
    })

    //错误码60005<等待主持人开始当前会议> 的回调监听
    this.$eventBus.$on('wait-host-handle', (error, cb, params) => {
      this.detail = error?.data || {}
      // this.hostWinTitle = error?.msg //bug 35512 要求文案改成“进入”
      this.hostWinTitle = "请等待主持人进入当前会议"
      this.waitParams = { cb, params }
      this.showOtherWin = false
      this.showHostWin = true
    })

    //错误码60011<主持人正在开另外一场会议> 的回调监听
    this.$eventBus.$on('other-host-handle', (error, cb, params) => {
      this.otherWinTitle = error?.msg
      this.otherParams = { cb, params }
      this.showHostWin = false
      this.showOtherWin = true
    })

    //错误码60010<是否结束当前正在进行中的会议> 的回调监听
    this.$eventBus.$on('end-meeting-handle', (error, cb, params) => {
      this.endDetail = error?.data || {}
      this.endWinTitle = error?.msg
      const roomId = this.endDetail.roomId
      this.endParams = { cb, params, roomId }
      this.showEndWin = true
    })

    if(this.$route.query.autoJoin == 1) {
      // this.$router.replace({autoJoin: 0})
      setTimeout(() => {
        this.handleClick();
      }, 200)
      return;
    }

    this.onExceptionExit();
  },

  methods: {
    closeWin(mark) {
      switch (mark) {
        case 0:
          this.showPwdWin = false
          break;

        case 1:
          this.showHostWin = false
          break;

        case 2:
          this.showOtherWin = false
          break;

        case 3:
          this.showEndWin = false
          break;

        default:
          break;
      }
      this.loading = false
    },
    handleClick: throttle(async function() {
      try {
          const resData = await landingConfirm({
            token: getMeetToken()
          })
          console.error('landingConfirm res',resData.success,resData)
          if (resData.success) {
            this.conferenceNo = resData.info.code
            this.userName = resData.info.username
            this.userId = resData.info.userId
            this.appId = resData.info.appIdList
            setAppid(this.appId)
            if (resData.info.requirePassword) {
              this.password = resData.info.password ?  resData.info.password :''
            }
              if (this.isNew) {
                this.$emit('join-before', this.handleJoin)
              } else {
                this.handleJoin()
              }
          } else {
            if(resData.error.includes('会议已结束') || resData.error.includes('会议不存在')){
              this.$router.push({
                  name: "endJoin",
                });
            }else{
              this.$dialog.confirm({
                message: resData.error,
                confirmButtonText: '确定',
                closeOnPopstate: false,
                showCancelButton:false,
              })
            }
          }
        } catch (error) {
          //todo 错误处理
          this.$toast(error.msg)
        }
     
    }, THROTLE_TIME,{ leading: true, trailing: false}),

    async handleJoin(joinPassword, conferenceNo, callback) {

      await checkDevicePermission()
      .then(() => {
        this.joinMeetFn(joinPassword, conferenceNo, callback)
      })
      .catch((error) => {
        if(error.type === "video"){
          this.$dialog.confirm({
            message:`系统检测未授权摄像头权限，是否继续加入会议`, 
            title:'提示', 
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() => {
            this.joinMeetFn(joinPassword, conferenceNo, callback)
          }).catch(() => {
                    
          });
        }else{
          this.$dialog.alert({
            message: '系统检测未授权麦克风权限，请关闭小程序重新打开后入会',
            confirmButtonText: '确定',
            confirmButtonColor: '#FF4D4F'
          })
        }
      })
    },

    // 加入会议
    async joinMeetFn(joinPassword, conferenceNo, callback) {
      joinPassword = this.password ? this.password : joinPassword
      this.conferenceNumber = conferenceNo ? conferenceNo : this.conferenceNo
      this.userName = this.userName ?  this.userName  : getUserName()

      this.userId = this.userId ? this.userId :  getUserId()
      if (!this.conferenceNumber || !this.userName) {
        return
      }

      let data = {
        conferenceNo: this.conferenceNumber.replace(/\s+/g, ""),
        userName: this.userName,
        closeConference: true,
        userId: this.userId,
        appId :getAppid() ? getAppid() : this.appId,
      }


      if (joinPassword) {
        data.password = joinPassword
      }

      try {
        const resData = await sdk.meetingManagement.joinMeeting(data)
        const { conference, userid, roomid } = resData

        this.$emit('join-finish', resData)
        callback && callback(1, resData)
        loganLog(`[debug info] join meeting success ${JSON.stringify(resData)}` )
        // 存储token信息
        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        // 更新会议信息
        this.$store.commit("meet/updateGlobalMeetState", {
          ...conference
        })

        this.loading = false
        setUserName(this.userName)
        setUserId(this.userId)
        this.$router.push({
          name: "meeting",
          params: { userID: userid },
          query: {
            roomID: roomid, // 房间id
            conferenceNo: conference.conferenceNo, // 会议号
            conferenceId: conference.id, // 会议id
            userName: encodeURIComponent(this.userName), // 展示名
            autoJoin: this.$route.query.autoJoin || 0
          }
        });
      } catch (error) {
        this.loading = false
        this.$eventBus.$emit('join-error', error, this.handleJoin, [joinPassword, conferenceNo])
        callback && callback(0, error)
      }
    },

    /*** --------- 监听异常退出 --------- ***/
    onExceptionExit() {
      try {
        this.$store.commit('meet/updateGlobalMeetState', {
          isShareDoc: false,
          shareFireId: null,
        });

        sessionStorage.removeItem('shareDocInfo')
      } catch(e) {
        console.error('异常退会清理文档共享失败',e)
      }

      // 锁屏 后台相关
      const appHide = localStorage.getItem('appHide');

      if(appHide) {
        this.$dialog.confirm({
          message: '异常退出会议，是否恢复会议',
          confirmButtonText: '重新入会',
          closeOnPopstate: false,
        }).then(() => {
          console.error('测试重新入会是否调用confirm接口-----')
          this.handleClick('', appHide);
          localStorage.removeItem('appHide');
          localStorage.removeItem('joinBeforeRoute');
  
        }).catch(() => {
          localStorage.removeItem('appHide');
          localStorage.removeItem('joinBeforeRoute');
        })
        

        return

      }

      const exceptionExitMsgObj = localStorage.getItem('exceptionExitMsg');

  
  
      if(!exceptionExitMsgObj) return;
      
      const { msg:exceptionExitMsg, conferenceNo } = JSON.parse(exceptionExitMsgObj);
  
      const _fun = message => {
        loganLog(message, '异常退会|' + conferenceNo)

        this.$dialog.confirm({
          message,
          confirmButtonText: '重新入会',
          closeOnPopstate: false,
        }).then(() => {
          localStorage.setItem('exceptionExitMsg', '');
  
          console.log('joinButton exception', exceptionExitMsg, conferenceNo);
          this.handleJoin('', conferenceNo);
  
        }).catch(() => {
          localStorage.setItem('exceptionExitMsg', '')
  
        })
  
      }
  
      if(exceptionExitMsg == 'exception') {
  
        _fun('异常退出会议，是否恢复会议')
        return
      } else if(exceptionExitMsg == 'timeout') {
        _fun(this.$t('meeting.exception'))
        return
      } else if(exceptionExitMsg == 'enterRoomError') {
        _fun('进入房间失败')
        return
      }

    }


  }

};
</script>

<style lang="less" scoped>
   .button {
      width: 100%;
      height: 88px;
      border-radius: 10px;
      font-size: 32px;
      font-weight: 500;
      border-color: #1AB370;
      color: #1AB370;
   }
</style>
